import React from 'react'
import styled from 'styled-components'
import logo from '../assets/images/logo/swarmia-logo.svg'

const contactEmail = 'hello@swarmia.com'

export enum AccessEndedReason {
  TrialEnded = 'TrialEnded',
  ScheduledForDeletion = 'ScheduledForDeletion',
}

function getContent(variant: AccessEndedReason):
  | {
      header: string
      body: string
      mailto: string
    }
  | undefined {
  switch (variant) {
    case AccessEndedReason.TrialEnded:
      return {
        header: 'Your free Swarmia trial has ended',
        body: 'If you want to continue with a paid plan or need more time for evaluation, let us know.',
        mailto: `mailto:${contactEmail}?subject=Continue using Swarmia&body=Hi there, we'd like to continue using Swarmia after the free trial.`,
      }
    case AccessEndedReason.ScheduledForDeletion:
      return {
        header: 'Your Swarmia account has been scheduled for deletion',
        body: `Your account and all of its data will be deleted within a week. If you would like to cancel the deletion, please reach out to us.`,
        mailto: `mailto:${contactEmail}?subject=Continue using Swarmia&body=Hi there, we'd like to cancel data deletion.`,
      }
    default:
      return
  }
}

export default function AccountAccessEnded({
  variant,
}: {
  variant: AccessEndedReason
}) {
  const content = getContent(variant)

  if (!content) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100vw',
        minHeight: '100vh',
        padding: '30px',
        lineHeight: '28px',
        fontFamily: 'Factor A',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          color: 'black',
          marginBottom: '128px',
        }}
      >
        <div style={{ marginBottom: 64 }}>
          <img alt="Swarmia logo" src={logo} />
        </div>
        <div
          style={{
            marginBottom: '16px',
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '42px',
            maxWidth: '480px',
          }}
        >
          {content.header}
        </div>
        <div style={{ marginBottom: '24px', maxWidth: '480px' }}>
          {content.body}
        </div>
        <Link href={content.mailto}>{contactEmail}</Link>
      </div>
    </div>
  )
}

const Link = styled.a`
  color: black;
  border-bottom: 2px solid #e767c1;
  padding-bottom: 2px;
  transition: border-bottom-color 0.2s ease, color 0.2s ease;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;

  &:hover {
    border-bottom-color: #90166c;
    color: black;
  }
`
